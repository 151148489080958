/* You can add global styles to this file, and also import other style files */

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@import '~@angular/material/theming';

@include mat-core();

$eightbit-landing-primary: mat-palette($mat-blue, 100, 200, 50);
$eightbit-landing-accent: mat-palette($mat-yellow);
$eightbit-warning: mat-palette($mat-red);

$eightbit-landing-light-theme: mat-light-theme((
  color: (
    primary: $eightbit-landing-primary,
    accent: $eightbit-landing-accent,
    warn: $eightbit-warning
  )
));

$eightbit-landing-dark-theme: mat-dark-theme((
  color: (
    primary: $eightbit-landing-primary,
    accent: $eightbit-landing-accent,
    warn: $eightbit-warning
  )
));

.eightbit-light-theme {
  @include angular-material-color($eightbit-landing-light-theme);
}

.eightbit-dark-theme {
  @include angular-material-color($eightbit-landing-dark-theme);
}


html, body { height: 100%; width: 100%; }

html {
  overflow: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
